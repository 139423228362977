import domReady from '@wordpress/dom-ready';

domReady(() => {
  const blockContainer = document.querySelector(
    '.open-source-block__images-card'
  );

  if (blockContainer) {
    const cards = blockContainer.querySelectorAll(
      '.open-source__img-container'
    );

    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const scrollPour =
              ((blockContainer.getBoundingClientRect().bottom - 600) * 100) /
              window.innerHeight;
            const leftBackImageRotation = -20;
            const rightBackImageRotation = 20;
            const leftBackImageTranslation = -140;
            const rightBackImageTranslation = 140;

            const leftFrontImageRotation = -48;
            const rightFrontImageRotation = 48;
            const leftFrontImageTranslation = -280;
            const rightFrontImageTranslation = 280;

            if (100 >= scrollPour && 0 <= scrollPour) {
              cards[0].style.transform =
                'rotate(' +
                getPixelByPourcentage(scrollPour, leftBackImageRotation) +
                'deg) translate( calc(' +
                getPixelByPourcentage(scrollPour, leftBackImageTranslation) +
                'px + 70px ), 30px)';

              cards[1].style.transform =
                'rotate(' +
                getPixelByPourcentage(scrollPour, leftFrontImageRotation) +
                'deg) translate(' +
                getPixelByPourcentage(scrollPour, leftFrontImageTranslation) +
                'px, 65px)';

              cards[2].style.transform =
                'rotate(' +
                getPixelByPourcentage(scrollPour, rightBackImageRotation) +
                'deg) translate(calc(' +
                getPixelByPourcentage(scrollPour, rightBackImageTranslation) +
                'px - 70px ), 30px)';

              cards[3].style.transform =
                'rotate(' +
                getPixelByPourcentage(scrollPour, rightFrontImageRotation) +
                'deg) translate(' +
                getPixelByPourcentage(scrollPour, rightFrontImageTranslation) +
                'px, 65px)';
            }

            // Arrêter d'observer une fois que la transformation est appliquée
            observer.unobserve(blockContainer);
          }
        });
      });

      function getPixelByPourcentage(pourcentage, max) {
        return (max * pourcentage) / 100;
      }

      // Commencer à observer la section
      observer.observe(blockContainer);

      // Gestionnaire d'événements de défilement pour réobserver lorsque la page est scrollée
      window.addEventListener('scroll', () => {
        observer.observe(blockContainer);
      });
    }
  }
});
